import React, { lazy, Suspense, useEffect, useState, useRef } from "react";
import axios from 'axios';
import { MdLocationOn, MdEmail, MdPhone, MdDirectionsCar, MdBusiness, MdEdit } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import '../components/css/edit.css';
import Swal from 'sweetalert2';
import MapExample from './MapExample';
import { geocodeAddress } from "./Geocodificação";
import Spinner from './Spinner';

const LazyMapExample = lazy(() => import("./MapExample"));

const TransportDetailsModal = ({ isOpen, onClose, transport, userToken }) => {
    const [pickupPhotos, setPickupPhotos] = useState([]);
    const [DropoffPhotos, setDropoffPhotos] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [drivers, setDrivers] = useState([]);
    const [dispatcher, setDispatcher] = useState([]);
    const [loading, setLoading] = useState(false);
    const carouselRef = useRef(null);
    const [editedData, setEditedData] = useState({});
    const [currentTransport, setCurrentTransport] = useState(transport);
    const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);
    const [pickupCoords, setPickupCoords] = useState(null);
    const [deliveryCoords, setDeliveryCoords] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [mapError, setMapError] = useState(false);



    useEffect(() => {
        if (isOpen && transport) {
            fetchTransportData();
            fetchDrivers();
            setEditedData({});
        }
    }, [isOpen, transport]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (carouselRef.current && !carouselRef.current.contains(event.target)) {
                closeCarousel();
            }
        };

        if (isCarouselOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isCarouselOpen]);

    useEffect(() => {
        if (isOpen && transport) {
            setEditedData({ ...transport }); // Inicializa os dados editáveis
            fetchTransportData();
        }
    }, [isOpen, transport]);

    const fetchTransportData = async () => {
        setLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport/${transport.id}`,
                {
                    headers: {
                        "Screen-Name": "/NewLoad",
                        "Authorization": `Bearer ${userToken}`
                    }
                }
            );

            const updatedTransport = response.data.data;
            // console.log("Dados atualizados do transporte:", updatedTransport); // Dados de transporte atualizados

            setCurrentTransport(updatedTransport);

            const loadItems = updatedTransport.load_items || [];
            // console.log("Itens de carga:", loadItems); // Itens de carga retornados

            const inspectionPhotos = loadItems.flatMap(item => item.inspection_photos || []);
            // console.log("Fotos de inspeção:", inspectionPhotos); // Fotos de inspeção

            const pickupPhotos = inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'pickup');
            const dropoffPhotos = inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'dropoff');

            setPickupPhotos(pickupPhotos);
            setDropoffPhotos(dropoffPhotos);
        } catch (error) {
            // console.error("Erro ao buscar dados do backend:", error); // Log de erro detalhado
        } finally {
            setLoading(false);

        }
    };
    const fetchDrivers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/driver`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            setDrivers(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar drivers", error);
        }
    };

    const handlePreviewClick = () => {
        if (!mapError) {
            setShowMap(true);
        }
    };

    const handleMapError = () => {
        setMapError(true);
        console.error("Failed to load the map. Please check the coordinates.");
    };

    const openCarousel = (photos) => {
        const images = photos.map(photo => ({
            original: `${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`,
            thumbnail: `${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`
        }));
        setCarouselImages(images);
        setIsCarouselOpen(true);
    };

    const closeCarousel = () => {
        setIsCarouselOpen(false);
    };

    const handleEditClick = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
        if (!editMode[field]) {
            setEditedData((prev) => ({ ...prev, [field]: currentTransport[field] }));
        }
    };

    const handleChange = (e, field) => {
        const value = e.target.value;
        setEditedData((prev) => ({ ...prev, [field]: value }));
    };

    const handleCancel = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: false }));
        setEditedData((prev) => ({ ...prev, [field]: currentTransport[field] }));
    };

    const handleChangeVehicleData = (field, value) => {
        setEditedData((prev) => {
            const updatedLoadItems = [...(prev.load_items || currentTransport.load_items)];
            updatedLoadItems[selectedVehicleIndex] = {
                ...updatedLoadItems[selectedVehicleIndex],
                [field]: value,
            };
            return { ...prev, load_items: updatedLoadItems };
        });
    };




    const handleSave = async (field) => {
        try {
            const dataToSend = { [field]: editedData[field] };
            await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport-update-view-details/${currentTransport.id}`,
                dataToSend,
                {
                    headers: {
                        "Screen-Name": "/Load",
                        "Authorization": `Bearer ${userToken}`,
                    },
                }
            );

            setCurrentTransport((prev) => ({ ...prev, [field]: editedData[field] }));
            setEditMode((prev) => ({ ...prev, [field]: false }));
            Swal.fire({
                title: 'Success!',
                text: 'Information updated successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                window.location.href = '/Load';
            });
        } catch (error) {
            console.error("Erro ao atualizar dados:", error);
        }
    };

    useEffect(() => {
        const fetchCoordinates = async () => {
            if (transport?.pickup_address && transport?.pickup_city) {
                const address = `${transport.pickup_address}, ${transport.pickup_city}, ${transport.pickup_state}, ${transport.pickup_zipcode}`;
                const coords = await geocodeAddress(address);
                if (coords && coords.lat && coords.lng) {
                    setPickupCoords([coords.lat, coords.lng]);
                } else {
                    // console.error("Failed to fetch pickup coordinates", coords);
                }
            }

            if (transport?.delivery_city && transport?.delivery_state) {
                const address = `${transport.delivery_city}, ${transport.delivery_state}`;
                const coords = await geocodeAddress(address);
                if (coords && coords.lat && coords.lng) {
                    setDeliveryCoords([coords.lat, coords.lng]);
                } else {
                    // console.error("Failed to fetch delivery coordinates", coords);
                }
            }
        };


        if (isOpen) fetchCoordinates();
    }, [isOpen, transport]);


    const handleSaveAll = async () => {
        setLoading(true);
        try {
            const payload = {
                ...editedData,
                load_items: editedData.load_items || currentTransport.load_items,
            };

            console.log("Payload enviado:", payload); // Verifique se os dados estão corretos

            await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport-update-view-details/${currentTransport.id}`,
                payload,
                {
                    headers: {
                        "Screen-Name": "/Load",
                        "Authorization": `Bearer ${userToken}`,
                    },
                }
            );
            setCurrentTransport(editedData);
            Swal.fire({
                title: "Success!",
                text: "Transport details updated successfully!",
                icon: "success",
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: false,
            }).then(() => {
                window.location.reload();
            });
            onClose();
        } catch (error) {
            console.error("Erro ao enviar atualização:", error);
            Swal.fire({
                title: "Error",
                text: "Failed to update transport details.",
                icon: "error",
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } finally {
            setLoading(false);
        }
    };





    const fetchInspectionStatus = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport/${currentTransport.id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${userToken}`,
                        "Screen-Name": "/NewLoad",
                    },
                }
            );

            const loadTransportData = response.data.data;

            // Captura o status do transporte
            const loadStatus = loadTransportData.load_status;
            setEditedData((prev) => ({
                ...prev,
                load_status_name: loadStatus?.load_status_name,
                load_status_description: loadStatus?.description,
            }));
        } catch (error) {
            console.error("Erro ao buscar o status do transporte:", error);
        }
    };



    useEffect(() => {
        if (isOpen && currentTransport) {
            fetchInspectionStatus();
        }
    }, [isOpen, currentTransport]);



    const isEditable = (status) => {
        const fullEditStatuses = ["assigned", "confirmed", "pickup"];
        const restrictedStatuses = ["delivery", "droppedoff", "paid"];

        if (fullEditStatuses.includes(status.toLowerCase())) {
            return "full";
        } else if (restrictedStatuses.includes(status.toLowerCase())) {
            return "limited";
        }
        return "none";
    };

    if (!isOpen || !currentTransport) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-start pt-10 z-50 bg-opacity-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="bg-white w-full max-w-4xl mx-4 sm:mx-6 lg:mx-8 p-4 sm:p-6 lg:p-8 rounded-lg shadow-xl overflow-auto transform transition-all duration-300 ease-out" style={{ fontFamily: 'Arial, sans-serif' }}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">Transport Details</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800 rounded p-2 focus:outline-none focus:ring-2 focus:ring-gray-400">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                {loading ? <Spinner /> : (
                    <div className="overflow-y-auto scrollbar-custom" style={{ maxHeight: '70vh' }}>
                        {/* Mapa */}
                        <div>

                            <hr className="" />

                            {/* Address warning */}
                            <p className="text-sm text-gray-600">
                                * Please provide real addresses from the USA to avoid search issues.
                            </p>

                            {/* Preview button */}
                            {!mapError && (
                                <div className="flex justify-center items-center mt-6">
                                    <button
                                        onClick={() => setShowMap(!showMap)} // Alterna entre exibir e ocultar o mapa
                                        className="px-6 py-3 bg-orange-500 text-white text-lg rounded-lg shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transform transition-all duration-200"
                                    >
                                        {showMap ? "Hide Map" : "Preview Map"} {/* Altera o texto dinamicamente */}
                                    </button>
                                </div>
                            )}

                            {/* Show error message */}
                            {mapError && (
                                <div className="mt-6 text-center text-red-500">
                                    <p>Error: Unable to load the map. Please ensure the addresses are correct.</p>
                                </div>
                            )}

                            {/* Show the map if showMap is true */}
                            {showMap && !mapError && pickupCoords && deliveryCoords && (
                                <Suspense fallback={<Spinner />}>
                                    <LazyMapExample
                                        pickupCoords={pickupCoords}
                                        deliveryCoords={deliveryCoords}
                                        onError={handleMapError}
                                    />
                                </Suspense>
                            )}

                            {!showMap && !mapError && (
                                <p className="text-center mt-4">Please click the preview button to load the map.</p>
                            )}
                        </div>
                        <div className="flex flex-wrap lg:flex-nowrap">

                            <div className="p-5 w-full lg:w-1/2">
                                <Section title="General Information" icon={<FaRegMoneyBillAlt />}>
                                    <hr className="" />
                                    <div className="flex flex-wrap -mx-2 mt-4">
                                        {/* Order ID */}
                                        <div className="w-1/2 sm:w-1/2 px-2">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Order ID:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.order_id || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, order_id: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        <div className="w-1/2 sm:w-1/2 px-2">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Driver:
                                            </label>

                                            <select
                                                defaultValue=""
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, driver_id: e.target.value }))
                                                }
                                                className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? ''
                                                ) === "limited"}
                                            >
                                                <option value="SelectDriver">Select a driver</option>
                                                {drivers.map((driver) => (
                                                    <option key={driver.id} value={driver.id}>
                                                        {driver.user?.first_name} {driver.user?.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Dispatcher Name */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Dispatch Name:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    editedData.dispatcher_name ??
                                                    `${currentTransport.dispatcher?.first_name || ''} ${currentTransport.dispatcher?.last_name || ''}`.trim()
                                                }
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        dispatcher_name: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white',
                                                }}
                                            />
                                        </div>


                                        {/* Dispatch Date */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Dispatch Date:
                                            </label>

                                            {/* formato ISO (YYYY-MM-DD) - A exibição visual depende do idioma/localização do navegador. */}
                                            <input
                                                type="date"
                                                value={editedData.dispatch_date || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, dispatch_date: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') === "limited"}
                                            />
                                        </div>

                                        {/* Loadboard */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Loadboard:
                                            </label>

                                            <select
                                                name="loadboard"
                                                value={editedData.loadboard || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, loadboard: e.target.value }))
                                                }
                                                className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            >
                                                <option value="SelectLoadboard" className="italic text-gray-400">
                                                    Select LoadBoard
                                                </option>
                                                {/* Options */}
                                                {[
                                                    'Cajuu',
                                                    'Central dispatch',
                                                    'Super dispatch',
                                                    'Ship.cars',
                                                    'Autosled',
                                                    'Acertus',
                                                    'Runbugy',
                                                    'Ready',
                                                    'Carsarrive',
                                                    'United',
                                                    'ACV Transportation',
                                                    'Uship',
                                                    'Email',
                                                ].map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>


                                        {/* Payment Rate */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Payment Rate:
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                value={editedData.payment_rate || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Valida se o valor não é negativo e é numérico
                                                    if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
                                                        setEditedData((prev) => ({ ...prev, payment_rate: value }));
                                                    }
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') === "limited"}
                                            />

                                        </div>

                                        {/* Payment Type */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Payment Type:
                                            </label>
                                            <select
                                                value={editedData.payment_type || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, payment_type: e.target.value }))
                                                }
                                                className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            >
                                                <option value="Selecttype" disabled className="italic text-gray-400">
                                                    Select payment type
                                                </option>
                                                <option value="COD">COD - Cash on delivery</option>
                                                <option value="COP">COP - Cash on pickup</option>
                                                <option value="2 BD">2 BD</option>
                                                <option value="5 BD">5 BD</option>
                                                <option value="7 BD">7 BD</option>
                                                <option value="15 BD">15 BD</option>
                                                <option value="30 BD">30 BD</option>
                                            </select>
                                        </div>

                                        {/* Payment Method */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Payment Method:
                                            </label>
                                            <select
                                                value={editedData.payment_method || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, payment_method: e.target.value }))
                                                }
                                                className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            >
                                                <option value="selectmethod" className="italic text-gray-400">
                                                    Select payment method
                                                </option>
                                                <option value="ACH">ACH</option>
                                                <option value="Check">Check</option>
                                                <option value="Zelle">Zelle</option>
                                                <option value="Venmo">Venmo</option>
                                                <option value="Cashapp">Cashapp</option>
                                                <option value="Uship">Uship</option>
                                                <option value="Cash">Cash</option>
                                                <option value="PayPal">PayPal</option>
                                                <option value="Credit card">Credit card</option>
                                                <option value="Debit">Debit</option>
                                                <option value="Comcheck">Comcheck</option>
                                                <option value="Echeck">Echeck</option>
                                                <option value="Eletronic Check">Eletronic Check</option>
                                            </select>
                                        </div>
                                    </div>
                                </Section>




                                <Section title="Vehicle Details" icon={<MdDirectionsCar />}>
                                    <hr className=''></hr>

                                    <div className="w-full mb-4 mt-4">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Select Vehicle:
                                        </label>
                                        <select
                                            value={selectedVehicleIndex}
                                            onChange={(e) => setSelectedVehicleIndex(Number(e.target.value))}
                                            className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        >
                                            {currentTransport.load_items.map((item, index) => (
                                                <option key={index} value={index}>
                                                    Vehicle {index + 1}: {item.make} {item.model} ({item.year})
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {currentTransport.load_items[selectedVehicleIndex] && (
                                        <div className="flex flex-wrap -mx-2 mb-4 mt-4">

                                            {/* VIN */}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    VIN:
                                                </label>
                                                <input
                                                    type="text"
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.vin ||
                                                        currentTransport.load_items[selectedVehicleIndex].vin
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("vin", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name ?? '') !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name ?? '') !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>

                                            {/* Year */}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Year:
                                                </label>
                                                <input
                                                    type="text"
                                                    maxLength={4}
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.year ||
                                                        currentTransport.load_items[selectedVehicleIndex].year
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("year", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>


                                            {/* Make */}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Make:
                                                </label>
                                                <input
                                                    type="text"
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.make ||
                                                        currentTransport.load_items[selectedVehicleIndex].make
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("make", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>

                                            {/* Model */}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Model:
                                                </label>
                                                <input
                                                    type="text"
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.model ||
                                                        currentTransport.load_items[selectedVehicleIndex].model
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("model", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>

                                            {/* Lot Number*/}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Lot Number:
                                                </label>
                                                <input
                                                    name="lotNumber"
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.lotNumber ||
                                                        currentTransport.load_items[selectedVehicleIndex].lotNumber
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("lotNumber", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>

                                            {/* Buyer Number*/}
                                            <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Buyer Number:
                                                </label>
                                                <input
                                                    name="buyer_number"
                                                    value={
                                                        editedData.load_items?.[selectedVehicleIndex]?.buyer_number ||
                                                        currentTransport.load_items[selectedVehicleIndex].buyer_number
                                                    }
                                                    onChange={(e) =>
                                                        handleChangeVehicleData("buyer_number", e.target.value)
                                                    }
                                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                    disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                    style={{
                                                        background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Section>
                            </div>

                            <div className="p-5 w-full lg:w-1/2">
                                <Section title="Pickup Information" icon={<MdLocationOn />}>
                                    <hr className="" />

                                    <div className="flex flex-wrap -mx-2 mt-4">
                                        {/* Pickup Company */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Contact Company:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_business_name || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_business_name: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Name */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Contact Name:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[0]?.customer_name || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[0]) {
                                                            updatedLoadItems[0].inspection_load_items[0].customer_name = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>


                                        {/* Pickup  Date Estimated */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Date Estimated:
                                            </label>
                                            <input
                                                type="date"
                                                value={editedData.expected_pickup_date || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, expected_pickup_date: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Email */}
                                        {/* <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                E-mail:
                                            </label>
                                            <input
                                                type="email"
                                                value={editedData.load_items.inspection_load_items.customer_email || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, customer_email: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div> */}


                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                E-mail:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[0]?.customer_email || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[0]) {
                                                            updatedLoadItems[0].inspection_load_items[0].customer_email = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Phone */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Phone:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[0]?.customer_phone || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[0]) {
                                                            updatedLoadItems[0].inspection_load_items[0].customer_phone = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>

                                        {/* <div className="w-full  px-2"> */}
                                        <div className=" w-full px-2 mt-4 ">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Address:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_address || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_address: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup City */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup City:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_city || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_city: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup State */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup State:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_state || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_state: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Zipcode */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Zipcode:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_zipcode || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Permite apenas números e até 5 dígitos
                                                    if (/^\d{0,5}$/.test(value)) {
                                                        setEditedData((prev) => ({ ...prev, pickup_zipcode: value }));
                                                    }
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Customer Name */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Customer Name:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_customer_name || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_customer_name: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Customer Phone */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Customer Phone:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.pickup_customer_phone || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Permite apenas números e até 11 dígitos
                                                    if (/^\d{0,11}$/.test(value)) {
                                                        setEditedData((prev) => ({ ...prev, pickup_customer_phone: value }));
                                                    }
                                                }}

                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Pickup Customer Email */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Pickup Customer Email:
                                            </label>
                                            <input
                                                type="email"
                                                value={editedData.pickup_customer_email || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, pickup_customer_email: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* Photo Gallery */}
                                    <PhotoGallery photos={pickupPhotos} openCarousel={openCarousel} className="mt-4" />
                                </Section>

                                <Section title="Delivery Information" icon={<MdLocationOn />}>
                                    <hr className="" />
                                    <div className="flex flex-wrap -mx-2 mt-4">
                                        {/* Delivery Company */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Contact Company:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_business_name || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, delivery_business_name: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Delivery Name */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Contact Name:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[1]?.customer_name || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[1]) {
                                                            updatedLoadItems[0].inspection_load_items[1].customer_name = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>

                                        {/* Delivery Date Estimated */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Delivery Date Estimated:
                                            </label>
                                            <input
                                                type="date"
                                                value={editedData.expected_dropoff_date || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({ ...prev, expected_dropoff_date: e.target.value }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Delivery Email */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                E-mail:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[1]?.customer_email || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[1]) {
                                                            updatedLoadItems[0].inspection_load_items[1].customer_email = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>

                                        {/* Delivery Phone */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Phone:
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    // Verifica se load_items e inspection_load_items existem antes de acessar customer_name
                                                    editedData.load_items?.[0]?.inspection_load_items?.[1]?.customer_phone || ''
                                                }
                                                onChange={(e) => {
                                                    const newCustomerName = e.target.value;

                                                    setEditedData((prev) => {
                                                        // Atualiza o customer_name no primeiro item de inspection_load_items
                                                        const updatedLoadItems = [...(prev.load_items || [])];
                                                        if (updatedLoadItems[0]?.inspection_load_items?.[1]) {
                                                            updatedLoadItems[0].inspection_load_items[1].customer_phone = newCustomerName;
                                                        }

                                                        return {
                                                            ...prev,
                                                            load_items: updatedLoadItems,
                                                        };
                                                    });
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== 'full'}
                                                style={{
                                                    background:
                                                        isEditable(currentTransport.load_status.load_status_name) !== 'full'
                                                            ? 'gainsboro'
                                                            : 'white',
                                                }}
                                            />
                                        </div>



                                        {/* Delivery Address */}
                                        <div className="w-full px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Delivery Address:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_address || currentTransport.delivery_address || ''}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        delivery_address: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Delivery City */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Delivery City:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_city || currentTransport.delivery_city}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        dropoff_city: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Delivery State */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Delivery State:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_state || currentTransport.delivery_state}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        dropoff_state: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Delivery Zipcode */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Delivery Zipcode:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_zipcode || currentTransport.delivery_zipcode}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Permite apenas números e até 5 dígitos
                                                    if (/^\d{0,5}$/.test(value)) {
                                                        setEditedData((prev) => ({ ...prev, dropoff_zipcode: value }));
                                                    }
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Customer Name */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Customer Name:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_customer_name || currentTransport.delivery_customer_name}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        delivery_customer_name: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Customer Phone */}
                                        <div className="w-1/2 sm:w-1/2 px-2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Customer Phone:
                                            </label>
                                            <input
                                                type="text"
                                                value={editedData.delivery_customer_phone || currentTransport.delivery_customer_phone}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Permite apenas números e até 11 dígitos
                                                    if (/^\d{0,5}$/.test(value)) {
                                                        setEditedData((prev) => ({ ...prev, delivery_customer_phone: value }));
                                                    }
                                                }}

                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>

                                        {/* Customer Email */}
                                        <div className="w-1/2 px-2 sm:w-1/2 mt-4">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Customer Email:
                                            </label>
                                            <input
                                                type="email"
                                                value={editedData.delivery_customer_email || currentTransport.delivery_customer_email}
                                                onChange={(e) =>
                                                    setEditedData((prev) => ({
                                                        ...prev,
                                                        delivery_customer_email: e.target.value,
                                                    }))
                                                }
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                disabled={isEditable(currentTransport.load_status.load_status_name) !== "full"}
                                                style={{
                                                    background: isEditable(currentTransport.load_status.load_status_name) !== "full" ? 'gainsboro' : 'white'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <PhotoGallery photos={DropoffPhotos} openCarousel={openCarousel} />
                                </Section>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex justify-end sm:justify-center lg:justify-end mt-4">
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                        onClick={handleSaveAll}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>

    );
}


const Section = ({ title, children, icon }) => (
    <div className="mb-6">
        <h3 className="text-xl font-semibold flex items-center mb-4">
            {icon && <span className="mr-2 text-lg">{icon}</span>}
            {title}
        </h3>
        {children}
    </div>
);

const InfoRow = ({ icon, label, value, editMode, handleEditClick, handleChange, handleSave, handleCancel, isEditable, options }) => {
    const displayValue = options && value
        ? options.find(option => option.id === value)?.user?.first_name + ' ' + options.find(option => option.id === value)?.user?.last_name
        : value;

    return (
        <div className="flex items-center mb-2">
            {icon && <div className="mr-2">{icon}</div>}
            <div className="font-medium mr-2"><strong>{label}</strong>:</div>
            {editMode ? (
                <div className="flex items-center">
                    {options ? (
                        <select
                            className="border p-1 mr-2"
                            value={value || ""}
                            onChange={handleChange}
                        >
                            <option value="">No driver assigned</option>
                            {options.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.user ? `${option.user.first_name} ${option.user.last_name}` : 'Unknown Driver'}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type="text"
                            className="border p-1 mr-2"
                            value={value}
                            onChange={handleChange}
                        />
                    )}
                    <button onClick={handleSave} className="text-blue-500 mr-2">Save</button>
                    <button onClick={handleCancel} className="text-red-500">Cancel</button>
                </div>
            ) : (
                <div className="flex items-center">
                    <span>{displayValue}</span>
                    {isEditable && <MdEdit className="ml-2 cursor-pointer" onClick={handleEditClick} />}
                </div>
            )}
        </div>
    );
};

const AddressInfo = ({ transport, type, editMode, editedData, handleEditClick, handleChange, handleSave }) => (
    <>
        <InfoRow
            label="Address"
            value={editedData[`${type}_address`] ?? transport[`${type}_address`]}
            editMode={editMode[`${type}_address`]}
            handleEditClick={() => handleEditClick(`${type}_address`)}
            handleChange={(e) => handleChange(e, `${type}_address`)}
            handleSave={() => handleSave(`${type}_address`)}
            isEditable
        />
        <InfoRow
            label="City"
            value={editedData[`${type}_city`] ?? transport[`${type}_city`]}
            editMode={editMode[`${type}_city`]}
            handleEditClick={() => handleEditClick(`${type}_city`)}
            handleChange={(e) => handleChange(e, `${type}_city`)}
            handleSave={() => handleSave(`${type}_city`)}
            isEditable
        />
        <InfoRow
            label="State"
            value={editedData[`${type}_state`] ?? transport[`${type}_state`]}
            editMode={editMode[`${type}_state`]}
            handleEditClick={() => handleEditClick(`${type}_state`)}
            handleChange={(e) => handleChange(e, `${type}_state`)}
            handleSave={() => handleSave(`${type}_state`)}
            isEditable
        />
        <InfoRow
            label="Zipcode"
            value={editedData[`${type}_zipcode`] ?? transport[`${type}_zipcode`]}
            editMode={editMode[`${type}_zipcode`]}
            handleEditClick={() => handleEditClick(`${type}_zipcode`)}
            handleChange={(e) => handleChange(e, `${type}_zipcode`)}
            handleSave={() => handleSave(`${type}_zipcode`)}
            isEditable
        />
        <InfoRow
            label="Customer Name"
            value={editedData[`${type}_customer_name`] ?? transport[`${type}_customer_name`]}
            editMode={editMode[`${type}_customer_name`]}
            handleEditClick={() => handleEditClick(`${type}_customer_name`)}
            handleChange={(e) => handleChange(e, `${type}_customer_name`)}
            handleSave={() => handleSave(`${type}_customer_name`)}
            isEditable
        />
        <InfoRow
            label="Customer Phone"
            value={editedData[`${type}_customer_phone`] ?? transport[`${type}_customer_phone`]}
            icon={<MdPhone />}
            editMode={editMode[`${type}_customer_phone`]}
            handleEditClick={() => handleEditClick(`${type}_customer_phone`)}
            handleChange={(e) => handleChange(e, `${type}_customer_phone`)}
            handleSave={() => handleSave(`${type}_customer_phone`)}
            isEditable
        />
        <InfoRow
            label="Customer Email"
            value={editedData[`${type}_customer_email`] ?? transport[`${type}_customer_email`]}
            icon={<MdEmail />}
            editMode={editMode[`${type}_customer_email`]}
            handleEditClick={() => handleEditClick(`${type}_customer_email`)}
            handleChange={(e) => handleChange(e, `${type}_customer_email`)}
            handleSave={() => handleSave(`${type}_customer_email`)}
            isEditable
        />
    </>
);

const PhotoGallery = ({ photos, openCarousel, isModalOpen, currentPhoto, closeModal }) => {
    // Ensure `photos` is always an array
    const safePhotos = Array.isArray(photos) ? photos : [];

    return (
        <>
            {/* Gallery */}
            <div className="flex flex-wrap justify-center mt-4">
                {safePhotos.length > 0 ? (
                    safePhotos.map((photo, index) => (
                        <div
                            key={index}
                            className="w-full bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg m-2"
                            onClick={() => openCarousel(safePhotos)}
                        >
                            <img
                                src={`${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`}
                                alt={`Inspection ${index + 1}`}
                                className="w-full h-32 object-cover cursor-pointer rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-200 ease-out"
                            />
                        </div>
                    ))
                ) : (
                    <>
                        <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700 w-full">
                            <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">
                                Photos of Vehicle
                            </h2>
                        </div>
                        <div className="w-full bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg p-4 m-2 flex justify-center items-center">
                            <p className="text-gray-600 dark:text-gray-400">No photos available</p>
                        </div>
                    </>
                )}
            </div>

            {/* Modal */}
            {isModalOpen && currentPhoto && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-screen-lg w-full">
                        <div className="relative">
                            <img
                                src={
                                    currentPhoto.path.startsWith('http')
                                        ? currentPhoto.path
                                        : `${process.env.REACT_APP_API_FILE_PREFIX}/${currentPhoto.path}`
                                }
                                alt="Current Photo"
                                className="w-full h-auto"
                            />
                            <button
                                className="absolute top-4 right-4 bg-red-500 text-white rounded-full p-2 shadow-md hover:bg-red-600"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


export default TransportDetailsModal;