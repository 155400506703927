import axios from "axios";

const addressCache = {};

export const geocodeAddress = async (address) => {
    if (!address) return null;

    // Verifica se a geolocalização já está no cache
    if (addressCache[address]) {
        return addressCache[address];
    }

    try {
        const response = await axios.get("https://nominatim.openstreetmap.org/search", {
            params: {
                q: address,
                format: "json",
            },
        });

        if (response.data && response.data.length > 0) {
            const coordinates = {
                lat: parseFloat(response.data[0].lat),
                lng: parseFloat(response.data[0].lon),
            };

            // Armazena no cache
            addressCache[address] = coordinates;
            return coordinates;
        }
    } catch (error) {
        console.error("Erro ao obter coordenadas:", error);
    }

    return null;
};
