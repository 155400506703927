import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import ImagemPickup from "../assets/img/pickup_marker.png";
import ImagemDelivery from "../assets/img/delivery_marker.png";

const MapExample = ({ pickupCoords, deliveryCoords }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map("map").setView([0, 0], 2);

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(mapRef.current);
        }

        // Remove camadas antigas para evitar duplicação
        mapRef.current.eachLayer((layer) => {
            if (layer instanceof L.Marker || layer instanceof L.Popup) {
                mapRef.current.removeLayer(layer);
            }
        });

        const markers = [];

        // Configurações dos ícones personalizados
        const pickupIcon = L.icon({
            iconUrl: ImagemPickup,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [0, -41],
        });

        const deliveryIcon = L.icon({
            iconUrl: ImagemDelivery,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [0, -41],
        });

        // Adiciona marcador e popup para Pickup
        if (pickupCoords) {
            const pickupMarker = L.marker(pickupCoords, { icon: pickupIcon }).addTo(mapRef.current);
            L.popup({ closeOnClick: false, autoClose: false })
                .setLatLng(pickupCoords)
                .setContent("Pickup")
                .openOn(mapRef.current);
            markers.push(pickupMarker);
        }

        // Adiciona marcador e popup para Delivery
        if (deliveryCoords) {
            const deliveryMarker = L.marker(deliveryCoords, { icon: deliveryIcon }).addTo(mapRef.current);
            L.popup({ closeOnClick: false, autoClose: false })
                .setLatLng(deliveryCoords)
                .setContent("Delivery")
                .openOn(mapRef.current);
            markers.push(deliveryMarker);
        }

        // Ajusta o mapa para mostrar os dois pontos
        if (pickupCoords && deliveryCoords) {
            const bounds = L.latLngBounds([pickupCoords, deliveryCoords]);
            mapRef.current.fitBounds(bounds);
        }

        return () => {
            // Remove todos os marcadores ao desmontar
            markers.forEach((marker) => mapRef.current.removeLayer(marker));
        };
    }, [pickupCoords, deliveryCoords]);

    return <div id="map" style={{ height: "400px", width: "100%", borderRadius: "8px" }} />;
};

export default React.memo(MapExample);
